import React, { useState } from 'react';
import { Input, Toast } from 'antd-mobile';
import Apis from 'src/apis';
import './index.less';
var ModifyNick = function (props) {
    var _a = useState(), value = _a[0], setValue = _a[1];
    var _b = useState(false), tip = _b[0], setTip = _b[1];
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var sureHandle = function () {
        var regex = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,7}$/;
        if (regex.test(value)) {
            setTip(false);
            Apis.modifyName({
                userId: userInfo.userId,
                oldNickname: props.name,
                newNickname: value
            }).then(function (res) {
                console.log(res.data);
                Toast.show({
                    content: '修改成功'
                });
                props.close(value);
            });
        }
        else {
            setTip(true);
        }
    };
    var inputChange = function (value) {
        setValue(value);
    };
    return React.createElement("div", { className: 'modify-nick-wrapper' },
        React.createElement("div", { className: 'modify-content' },
            React.createElement("span", { className: 'close-icon', onClick: props.close }),
            React.createElement("div", { className: 'modify-new-input' },
                React.createElement("span", { className: 'new-nick-title' }, "\u65B0\u6635\u79F0\uFF1A"),
                React.createElement(Input, { className: 'input-style', defaultValue: props.name, placeholder: '\u8BF7\u8F93\u5165\u5185\u5BB9', clearable: true, onChange: inputChange })),
            tip && React.createElement("div", { className: 'input-tip' }, "\u8BF7\u8F93\u51651\uFF5E7\u4F4D\u7684\u4E2D\u6587\u3001\u82F1\u6587\u3001\u6570\u5B57"),
            React.createElement("div", { className: 'modify-btn', onClick: sureHandle },
                React.createElement("label", null, "\u786E\u5B9A"))));
};
export default ModifyNick;
