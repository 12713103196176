var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import Apis from 'src/apis';
export var Context = React.createContext(null);
var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
export var initialState = {
    // loginCurrentKey: 'login',
    // userId: '',
    // renewModalData: {
    //   visible: false,
    //   level: 0
    // },
    // upgradeModalData: {
    //   visible: false
    // },
    // modifyPayData: {
    //   visible: false,
    //   aliPayId: ''
    // },
    // customServiceModal: {
    //   visible: false
    // }
    userInfoData: {
        diamondCount: 0,
        goldCoinCount: 0,
        user: {
            nickName: '',
            phoneNum: '',
            shareQrCodeUrl: '',
            userId: ''
        }
    }
};
export var reduxSlice = createSlice({
    name: 'reduxSlice',
    initialState: initialState,
    reducers: {
        // setLoginCurrentKey: (state, { payload }) => {
        //   state.loginCurrentKey = payload
        // },
        // setUserId: (state, { payload }) => {
        //   state.userId = payload
        // },
        // setRenewModalData: (state, { payload }) => {
        //   state.renewModalData = payload
        // },
        // setUpgradeModalData: (state, { payload }) => {
        //   state.upgradeModalData = payload
        // },
        // setModifyPayData: (state, { payload }) => {
        //   state.modifyPayData = payload
        // },
        // setCustomServiceModal: (state, { payload }) => {
        //   state.customServiceModal = payload
        // }
        setUserGoldAndDiamondInfo: function (state, _a) {
            var payload = _a.payload;
            state.userInfoData = payload;
        }
    }
});
// Action creators are generated for each case reducer function
export var 
// setLoginCurrentKey,
// setUserId,
// setRenewModalData,
// setUpgradeModalData,
// setModifyPayData,
// setCustomServiceModal
setUserGoldAndDiamondInfo = reduxSlice.actions.setUserGoldAndDiamondInfo;
// redux-thunk actions
export var getUserInfo = function (_params) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var res;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                console.log(_params);
                if (!userInfo.userId) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, Apis.getUserInfo({
                        userId: userInfo.userId
                    })
                    // window.localStorage.setItem('diamondCount', res.data.userInfo?.diamondCount)
                    // window.localStorage.setItem('goldCoinCount', res.data.userInfo?.goldCoinCount)
                ];
            case 1:
                res = _b.sent();
                // window.localStorage.setItem('diamondCount', res.data.userInfo?.diamondCount)
                // window.localStorage.setItem('goldCoinCount', res.data.userInfo?.goldCoinCount)
                window.localStorage.setItem('user', JSON.stringify((_a = res.data.userInfo) === null || _a === void 0 ? void 0 : _a.user));
                dispatch(setUserGoldAndDiamondInfo(res.data.userInfo || {}));
                return [2 /*return*/];
        }
    });
}); }; };
// 拉取是否开通接口
// export const getIsOpen =
//   (query = {}) =>
//   async (dispatch) => {
//     const res = await Apis.getIsOpen(query).catch((err) => {
//       console.log('getIsOpen err', err)
//     })
//     const isOpen = res?.data ?? false
//     dispatch(setIsOpen(isOpen))
//   }
// 数据配置：拉取数据配置列表
// export const getDataTableList = (params) => async (dispatch) => {
//   const res = await Apis.getDataTableList(params).catch((err) => {
//     console.log('getDataTableList err', err)
//     return []
//   })
//   return res?.data
// }
// Reducer
export default reduxSlice.reducer;
