import React, { useState, useContext } from 'react';
import { Input, Toast } from 'antd-mobile';
import Apis from 'src/apis';
import { getUserInfo, Context } from 'src/store';
import './index.less';
var ExchargeCode = function (props) {
    var _a = useState(), value = _a[0], setValue = _a[1];
    // const [tip, setTip] = useState(false)
    var dispatch = useContext(Context).dispatch;
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var sureHandle = function () {
        Apis.exchargeCode({
            userId: userInfo.userId,
            exchangeCode: value
        }).then(function (res) {
            if (res.data.exchangeSuccess) {
                Toast.show({
                    content: '兑换成功'
                });
                dispatch(getUserInfo());
                props.close(value);
            }
            else {
                Toast.show({
                    content: res.data.exchangeFailedMsg
                });
            }
        });
    };
    var inputChange = function (value) {
        setValue(value);
    };
    return React.createElement("div", { className: 'modify-nick-wrapper' },
        React.createElement("div", { className: 'modify-content' },
            React.createElement("span", { className: 'close-icon', onClick: props.close }),
            React.createElement("div", { className: 'modify-new-input' },
                React.createElement("span", { className: 'new-nick-title' }, "\u5151\u6362\u7801\uFF1A"),
                React.createElement(Input, { className: 'input-style', defaultValue: props.name, placeholder: '\u8BF7\u8F93\u5165\u5151\u6362\u7801', clearable: true, onChange: inputChange })),
            React.createElement("div", { className: 'modify-btn', onClick: sureHandle },
                React.createElement("label", null, "\u786E\u5B9A"))));
};
export default ExchargeCode;
