import React, { useContext, useEffect, useState } from 'react';
import { Input, Toast } from 'antd-mobile';
import Apis from 'src/apis';
import { getUserInfo, Context } from 'src/store';
import './index.less';
var ExchargeGoldModal = function (props) {
    var _a = useState(), goldNum = _a[0], setGoldNum = _a[1];
    var _b = useState(0), consumeDiamondNum = _b[0], setConsumeDiamondNum = _b[1];
    var _c = useContext(Context), state = _c.state, dispatch = _c.dispatch;
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var userInfoData = state.userInfoData;
    var diamondChange = function (val) {
        var formattedValue = val.replace(/^0+/, '');
        var value = formattedValue * 1;
        var count = Math.trunc(value / 10);
        setGoldNum(count);
        setConsumeDiamondNum(val);
    };
    var exchargeGoldHandle = function () {
        Apis.exchargeGold({
            userId: userInfo.userId,
            diamondCount: consumeDiamondNum * 1
        }).then(function (res) {
            if (res.data.result) {
                Toast.show({
                    icon: 'success',
                    content: '兑换成功'
                });
                dispatch(getUserInfo());
                props.close();
            }
            else {
                Toast.show({
                    icon: 'fail',
                    content: res.data.failedMsg
                });
            }
        });
    };
    useEffect(function () {
        var maxGold = Math.trunc(consumeDiamondNum * 1 / 10);
        setGoldNum(maxGold);
    }, [consumeDiamondNum]);
    useEffect(function () {
        setConsumeDiamondNum(userInfoData.diamondCount);
    }, [userInfoData.diamondCount]);
    return React.createElement("div", { className: 'excharge-modal-wrapper2' },
        React.createElement("div", { className: 'excharge-modal-content' },
            React.createElement("span", { className: 'excharge-close-icon', onClick: props.close }),
            React.createElement("div", { className: 'excharge-content' },
                React.createElement("div", { className: 'excharge-content-left' }),
                React.createElement("div", { className: 'excharge-content-right' },
                    React.createElement("div", { className: 'excharge-content-item' },
                        React.createElement("span", { className: 'item-title xiaohao-icon' }, "\u6D88\u8017\uFF1A"),
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165', type: 'number', max: userInfoData.diamondCount, value: consumeDiamondNum, onChange: diamondChange, className: 'item-input zuanshi-input' })),
                    React.createElement("div", { className: 'excharge-content-item doubi-item' },
                        React.createElement("span", { className: 'item-title' }, "\u91D1\u5E01\uFF1A"),
                        React.createElement(Input, { placeholder: '\u81EA\u52A8\u6362\u7B97', type: 'number', disabled: true, value: goldNum, className: 'item-input doubi-input' })))),
            React.createElement("div", { className: 'excharge-sure-btn-wrapper', onClick: exchargeGoldHandle },
                React.createElement("span", null, "\u786E\u8BA4\u5151\u6362"))));
};
export default ExchargeGoldModal;
