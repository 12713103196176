import React from 'react';
import html2canvas from 'html2canvas';
import './index.less';
var ShareModal = function (props) {
    // const userInfo = JSON.parse(window.localStorage.getItem('user') || '{}')
    var saveImage = function () {
        var element = document.getElementById('share-content');
        html2canvas(element).then(function (canvas) {
            // 将 canvas 转换为图片
            var image = canvas.toDataURL('image/png');
            // 处理图片，例如下载或显示在页面上
            var link = document.createElement('a');
            // 设置链接的属性
            link.href = image;
            link.download = 'image.png'; // 设置下载文件的名称
            // 创建一个点击事件
            var clickEvent = new MouseEvent('click');
            // 模拟点击链接来触发下载
            link.dispatchEvent(clickEvent);
        });
    };
    return React.createElement("div", { className: 'share-wrapper' },
        React.createElement("div", { className: 'share-content', id: 'share-content' },
            React.createElement("span", { className: 'close-icon', onClick: props.close }),
            React.createElement("span", { className: 'share-num' }, "1000"),
            React.createElement("span", { className: 'share-ercode' }),
            React.createElement("div", { className: 'share-btn', onClick: saveImage }),
            React.createElement("div", { className: 'share-get-btn' }, "\u9886\u53D6")));
};
export default ShareModal;
