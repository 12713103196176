import React, { Suspense } from 'react';
import ComponentAppRoute from '../route';
// import Copyright from '@pages/Copyright'
import useEnhancedReducer from '../pages/Component/UseEnhancedReducer';
import reducer, { Context, initialState } from '../store';
import './index.less';
var App = function () {
    var _a = useEnhancedReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    return React.createElement(Suspense, { fallback: '\u52A0\u8F7D\u4E2D...' },
        React.createElement(Context.Provider, { value: { state: state, dispatch: dispatch } },
            React.createElement("div", { className: 'app-wrapper' },
                React.createElement(ComponentAppRoute, null))));
};
export default App;
