var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import build from './build';
var IS_DEV = build.IS_DEV;
var HOST = window.location.host;
var ORIGIN = window.location.origin;
var PATHNAME = window.location.pathname;
var SERACH = window.location.search;
var prodRootMap = {
    'game.tuanzhzh.com': {
        root: 'https://game.tuanzhzh.com/'
    }
};
var rootObj = prodRootMap[Object.keys(prodRootMap).find(function (key) { return HOST.indexOf(key) === 0; })] || {};
var DEFAULT_ROOT = '/';
var getRootStr = function (rootStr) {
    return rootStr || DEFAULT_ROOT;
};
var PROD = {
    APIS: {
        uploadMediaRoot: getRootStr(rootObj.uploadMediaRoot),
        uploadRoot: getRootStr(rootObj.uploadRoot),
        flowRoot: getRootStr(rootObj.flowRoot),
        root: getRootStr(rootObj.root),
        authRoot: getRootStr(rootObj.authRoot)
    }
};
var DEV = {
    APIS: {
        uploadMediaRoot: DEFAULT_ROOT,
        uploadRoot: DEFAULT_ROOT,
        flowRoot: DEFAULT_ROOT,
        root: DEFAULT_ROOT,
        authRoot: DEFAULT_ROOT
    }
};
var ssoService = encodeURIComponent("".concat(ORIGIN + PATHNAME + SERACH));
// 开发环境权限总开关 false - 关闭权限菜单， true - 开启权限菜单
var devAuthority = true;
var OBJ = __assign(__assign(__assign({ SUPER_ADMIN: ['wanghl@inke.cn', 'lin@inke.cn'], IS_DEV: IS_DEV, USER_INFO_MOCK: false, 
    // 手机登录情况下，登录页面路由地址
    PHONE_LOGGIN_PATH: 'login', SSO_PAGE_SERVICE: "http://sso.inkept.cn/?service=".concat(ssoService), SSO_PAGE_URL: 'http://sso.inkept.cn', SSO_LOGOUT_PAGE_SERVICE: "https://sso.inkept.cn/api/v1/user/logout/?service=".concat(ssoService), build: build }, build.ENV), IS_DEV ? DEV : PROD), { 
    // 该系统权限标识，获取请联系 @李宁，2 为测试系统
    // http://wiki.inkept.cn/pages/viewpage.action?pageId=50851740
    AUTH_SYSTEM_ID: 2, 
    // 系统中文名称
    SYSTEM_CHINA_NAME: '固定资产管理系统 ', 
    // 系统英文缩写
    SYSTEM_US_NAME: 'Legal', 
    // 系统地址
    SYSTEM_URL: 'legal.inkept.cn', 
    // 权限总开关 false - 关闭权限菜单， true - 开启权限菜单
    authority: IS_DEV ? devAuthority : true, 
    // 使用情况监控，获取请联系 @赵通，-1 则全局关闭监控
    // http://wiki.inkept.cn/pages/viewpage.action?pageId=54597351
    LOG_SYSTEM_SOURCE: -1 });
export default OBJ;
