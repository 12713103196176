import React from 'react';
import './index.less';
var GameIllustrate = function (props) {
    return React.createElement("div", { className: 'game-illustrate-wrapper' },
        React.createElement("div", { className: 'game-illustrate-content' },
            React.createElement("span", { className: 'close-icon', onClick: props.close }),
            React.createElement("ul", { className: 'content-text' },
                React.createElement("li", null, "1\u7EA7\u70AE\u6D88\u80171\u91D1\u5E01,2\u7EA7\u70AE\u6D88\u80175\u91D1\u5E01,3\u7EA7\u70AE\u6D88\u801710\u91D1\u5E01"),
                React.createElement("li", null, "\u60A8\u6210\u529F\u6355\u83B7\u9C7C\uFF0C\u7CFB\u7EDF\u4F1A\u8D60\u9001\u60A8\u5BF9\u5E94\u7684\u91D1\u5E01\u548C\u94BB\u77F3"),
                React.createElement("li", null, "\u83B7\u8D60\u7684\u94BB\u77F3\u53EF\u4EE5\u5151\u6362\u5404\u5927\u5E73\u53F0\u62B5\u7528\u5238\u6216\u6298\u6263\u5238"),
                React.createElement("li", null, "\u6BCF\u6210\u529F\u9080\u8BF7\u4E00\u4F4D\u670B\u53CB,\u60A8\u548C\u60A8\u7684\u670B\u53CB\u5747\u53EF\u83B7\u8D6010\u91D1\u5E01"),
                React.createElement("li", null, "\u4E3A\u907F\u514D\u60A8\u7684\u635F\u5931\uFF0C\u5173\u95ED\u6E38\u620F\u65F6\u8BF7\u5148\u70B9\u51FB\u5DE6\u4E0A\u89D2\u7684\u9000\u51FA\u6309\u94AE\u518D\u5173\u95ED\u7F51\u9875"))));
};
export default GameIllustrate;
