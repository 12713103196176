import React, { useContext, useState } from 'react';
import { Input, Toast } from 'antd-mobile';
import Apis from 'src/apis';
import { getUserInfo, Context } from 'src/store';
import './index.less';
var cardNum = '1';
var ExchargeModal = function (props) {
    // const [doubiNum, setDoubiNum] = useState<any>(1)
    // const [consumeDiamondNum, setConsumeDiamondNum] = useState<any>(0)
    var _a = useState(), dyId = _a[0], setDyId = _a[1];
    var _b = useContext(Context), state = _b.state, dispatch = _b.dispatch;
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var userInfoData = state.userInfoData;
    // const diamondChange = (val) => {
    //   // const formattedValue = val.replace(/^0+/, '')
    //   // const value = formattedValue * 1
    //   // const count = Math.trunc(value / 10)
    //   // setDoubiNum(count)
    //   setConsumeDiamondNum(val)
    // }
    var douyinHandle = function (val) {
        setDyId(val);
    };
    var exchargeDoubi = function () {
        // if (!dyId || !consumeDiamondNum) {
        //   return Toast.show({
        //     icon: 'fail',
        //     content: '相关内容不能为空'
        //   })
        // }
        var pattern = /^1[3-9]\d{9}$/;
        if (!pattern.test(dyId)) {
            return Toast.show({
                icon: 'fail',
                content: '手机号不合法'
            });
        }
        Toast.show({
            icon: 'loading',
            content: '加载中…'
        });
        Apis.exchargeCard({
            userId: userInfo.userId,
            couponId: props.consumeObj.couponId,
            // diamondCount: props.consumeObj.diamondCount * 1,
            exchangeCount: 1,
            exchangePhoneNum: dyId
        }).then(function (res) {
            if (res.data.exchangeResult) {
                Toast.show({
                    icon: 'success',
                    content: '兑换成功'
                });
                dispatch(getUserInfo());
                props.getExchargeList && props.getExchargeList();
                props.close();
            }
            else {
                Toast.show({
                    icon: 'fail',
                    content: res.data.failedMsg
                });
            }
        }).finally(function () {
            Toast.clear();
        });
    };
    // useEffect(() => {
    //   const maxGold = Math.trunc(consumeDiamondNum * 1 / 10)
    //   setDoubiNum(maxGold)
    // }, [consumeDiamondNum])
    // useEffect(() => {
    //   setConsumeDiamondNum(userInfoData.diamondCount)
    // }, [userInfoData.diamondCount])
    return React.createElement("div", { className: 'excharge-modal-wrapper' },
        React.createElement("div", { className: 'excharge-modal-content' },
            React.createElement("span", { className: 'excharge-close-icon', onClick: props.close }),
            React.createElement("div", { className: 'title-wrapper' },
                React.createElement("span", { className: 'title-name' }, props.consumeObj.couponName)),
            React.createElement("div", { className: 'excharge-content' },
                React.createElement("div", { className: 'excharge-content-left' },
                    React.createElement("img", { src: props.consumeObj.couponPicUrl })),
                React.createElement("div", { className: 'excharge-content-right' },
                    React.createElement("div", { className: 'excharge-content-item' },
                        React.createElement("span", { className: 'item-title xiaohao-icon' }, "\u6D88\u8017\uFF1A"),
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165', max: userInfoData.diamondCount, value: props.consumeObj.diamondCount || 0, disabled: true, 
                            // onChange={diamondChange}
                            type: 'number', className: 'item-input zuanshi-input' })),
                    React.createElement("div", { className: 'excharge-content-item doubi-item' },
                        React.createElement("span", { className: 'item-title' }, "\u5361\u52B5\uFF1A"),
                        React.createElement(Input, { placeholder: '\u81EA\u52A8\u6362\u7B97', type: 'number', value: cardNum, disabled: true, className: 'item-input doubi-input' })))),
            React.createElement("div", { className: 'douyin-number-wrapper' },
                React.createElement("span", { className: 'douyin-title' }, "\u624B\u673A\u53F7\uFF1A"),
                React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165\u624B\u673A\u53F7', className: 'douyin-input-style', clearable: true, value: dyId, onChange: douyinHandle })),
            React.createElement("div", { className: 'excharge-explain-word' },
                React.createElement("div", { className: 'excharce-space-wrapper' },
                    React.createElement("span", null, "\u5546\u54C1\u5C06\u4E8E 5 \u5206\u949F\u5185\u5230\u8D26"),
                    React.createElement("span", null, "\u8BF7\u52A1\u5FC5\u786E\u4FDD\u8F93\u5165\u7684\u624B\u673A\u53F7\u6B63\u786E"),
                    React.createElement("span", null, "\u8BF7\u52A1\u5FC5\u786E\u4FDD\u8F93\u5165\u7684\u624B\u673A\u53F7\u5DF2\u6CE8\u518C\u8BE5\u5E73\u53F0\u8D26\u53F7"),
                    React.createElement("span", null, "\u5982\u5546\u54C1\u8D2D\u4E70\u5F02\u5E38\uFF0C\u6D88\u8D39\u94BB\u77F3\u5C06\u539F\u8DEF\u9000\u56DE"))),
            React.createElement("div", { className: 'excharge-sure-btn-wrapper', onClick: exchargeDoubi },
                React.createElement("span", null, "\u786E\u8BA4\u5151\u6362"))));
};
export default ExchargeModal;
