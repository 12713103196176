import React, { useEffect, useState } from 'react';
import Apis from 'src/apis';
import { Toast, Empty } from 'antd-mobile';
import './index.less';
var SortModal = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useState(1), currentActive = _h[0], setCurrentActive = _h[1];
    var _j = useState({
        userRankingList: [],
        meRanking: {}
    }), rankingData = _j[0], setRankingData = _j[1];
    var _k = useState([]), threeData = _k[0], setThreeData = _k[1];
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    // const sortList = []
    var sortData = [{
            type: 'gold',
            name: '金币榜',
            key: 0
        }, {
            type: 'zuanshi',
            name: '钻石榜',
            key: 1
        }, {
            type: 'busha',
            name: '捕鲨榜',
            key: 2
        }];
    var btnHandle = function (index) {
        setCurrentActive(index);
        rankingList(String(index));
    };
    function phoneHide(phone) {
        var reg = /^(1[3-9][0-9])\d{4}(\d{4})$/;
        phone = phone.replace(reg, '$1****$2');
        return phone;
    }
    var rankingList = function (index) {
        Toast.show({
            icon: 'loading',
            content: '加载中…',
            duration: 0
        });
        Apis.rankingList({
            userId: userInfo.userId,
            userRankingTypeCode: index
        }).then(function (res) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            var data = res.data || {};
            if (data.userRankingList && data.userRankingList.length >= 3) {
                var reg_1 = /^1[345789]\d{9}$/;
                data.userRankingList.forEach(function (item) {
                    if (reg_1.test(item.user.nickName)) {
                        item.user.nickName = phoneHide(item.user.nickName);
                    }
                });
                var tempThree = data.userRankingList.splice(0, 3);
                setThreeData({
                    first: {
                        nickName: (_b = (_a = tempThree[0]) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.nickName,
                        stuffCount: (_c = tempThree[0]) === null || _c === void 0 ? void 0 : _c.stuffCount
                    },
                    two: {
                        nickName: (_e = (_d = tempThree[1]) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e.nickName,
                        stuffCount: (_f = tempThree[1]) === null || _f === void 0 ? void 0 : _f.stuffCount
                    },
                    three: {
                        nickName: (_h = (_g = tempThree[2]) === null || _g === void 0 ? void 0 : _g.user) === null || _h === void 0 ? void 0 : _h.nickName,
                        stuffCount: (_j = tempThree[2]) === null || _j === void 0 ? void 0 : _j.stuffCount
                    }
                });
            }
            setRankingData({
                userRankingList: data.userRankingList || [],
                meRanking: data.meRanking // data.onRankingThreshold > 100
            });
        }).finally(function () {
            // const tempThree = sortList.slice(0, 3)
            // setThreeData({
            //   first: {
            //     nickName: tempThree[0]?.user?.nickName,
            //     stuffCount: tempThree[0]?.stuffCount
            //   },
            //   two: {
            //     nickName: tempThree[1]?.user?.nickName,
            //     stuffCount: tempThree[1]?.stuffCount
            //   },
            //   three: {
            //     nickName: tempThree[2]?.user?.nickName,
            //     stuffCount: tempThree[2]?.stuffCount
            //   }
            // })
            // setRankingData({
            //   userRankingList: sortList
            //   // onRankingThreshold: true
            // })
            Toast.clear();
        });
    };
    useEffect(function () {
        if (props.visible) {
            rankingList('1');
        }
    }, [props.visible]);
    return React.createElement("div", { className: 'sort-modal-wrapper' },
        React.createElement("div", { className: 'sort-modal-content' },
            React.createElement("span", { className: 'sort-close-icon', onClick: props.close }),
            React.createElement("div", { className: 'sort-btn-wrapper' }, sortData.map(function (item, i) {
                return React.createElement("div", { key: i, className: currentActive === i + 1 ? 'btn-item active' : 'btn-item no-active', onClick: btnHandle.bind(null, i + 1) },
                    React.createElement("span", null, item.name));
            })),
            React.createElement("div", { className: 'fist-style' },
                React.createElement("span", { className: 'sort-icon' }),
                React.createElement("span", { className: 'sort-name' }, (_a = threeData.first) === null || _a === void 0 ? void 0 : _a.nickName),
                React.createElement("span", { className: 'sort-num' }, (_b = threeData.first) === null || _b === void 0 ? void 0 : _b.stuffCount)),
            React.createElement("div", { className: 'first-two-three' },
                React.createElement("div", { className: 'two-style' },
                    React.createElement("span", { className: 'sort-icon' }),
                    React.createElement("span", { className: 'sort-name' }, (_c = threeData.two) === null || _c === void 0 ? void 0 : _c.nickName),
                    React.createElement("span", { className: 'sort-num' }, (_d = threeData.two) === null || _d === void 0 ? void 0 : _d.stuffCount)),
                React.createElement("div", { className: 'three-style' },
                    React.createElement("span", { className: 'sort-icon' }),
                    React.createElement("span", { className: 'sort-name' }, (_e = threeData.three) === null || _e === void 0 ? void 0 : _e.nickName),
                    React.createElement("span", { className: 'sort-num' }, (_f = threeData.three) === null || _f === void 0 ? void 0 : _f.stuffCount))),
            React.createElement("div", { className: 'sort-list-wrapper' },
                React.createElement("div", { className: 'sort-list-content' }, (_g = rankingData.userRankingList) === null || _g === void 0 ? void 0 :
                    _g.map(function (item, i) {
                        var _a;
                        return React.createElement("div", { key: i, className: 'sort-list-item' },
                            React.createElement("span", { className: 'list-name' },
                                React.createElement("label", null, i + 4),
                                React.createElement("label", null, (_a = item.user) === null || _a === void 0 ? void 0 : _a.nickName)),
                            React.createElement("span", { className: 'list-num' }, item.stuffCount));
                    }),
                    !rankingData.userRankingList.length ? React.createElement(Empty, { style: { paddingTop: '100px' }, description: '\u6682\u65E0\u6570\u636E' }) : ''),
                rankingData.meRanking.order ? React.createElement("div", { className: 'sort-list-no' },
                    React.createElement("span", null,
                        React.createElement("label", null,
                            "\u60A8\u6392\u7B2C ",
                            rankingData.meRanking.order,
                            " \u518D\u63A5\u518D\u5389")),
                    React.createElement("span", null, rankingData.meRanking.stuffCount)) : undefined)));
};
export default SortModal;
