import React, { useEffect, useRef, useState } from 'react';
import Apis from 'src/apis';
import { Toast } from 'antd-mobile';
import './index.less';
var code = {
    1: 'doing',
    2: 'done',
    3: 'fail'
};
var ExchargeHistory = function (props) {
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState({
        page: 1,
        pageSize: 10
    }), param = _b[0], setParam = _b[1];
    var saveValueRef = useRef(true);
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    // 获取列表
    var getList = function () {
        Toast.show({
            icon: 'loading',
            content: '加载中…'
        });
        Apis.getRecordList({
            userId: userInfo.userId,
            page: param.page,
            pageSize: param.pageSize
        }).then(function (res) {
            saveValueRef.current = res.data.length === 10;
            var allData = data.concat(res.data || []);
            setData(allData);
        }, function () {
            saveValueRef.current = true;
        }).finally(function () {
            Toast.clear();
        });
    };
    // 滚动
    var scollHandle = function () {
        var dom = document.getElementById('content-id');
        // 判断是否滚动到底部
        if (dom.scrollHeight - dom.scrollTop <= dom.clientHeight + 1) {
            console.log('已经滚动到底部！');
            // 在这里执行你想要的操作
            if (saveValueRef.current) {
                saveValueRef.current = false;
                setParam({
                    page: param.page + 1,
                    pageSize: 10
                });
            }
        }
    };
    useEffect(function () {
        getList();
    }, [param]);
    useEffect(function () {
        var dom = document.getElementById('content-id');
        dom.addEventListener('scroll', scollHandle);
    }, []);
    return React.createElement("div", { className: "excharge-history" },
        React.createElement("div", { className: 'excharge-content-wrapper' },
            React.createElement("span", { className: 'excharge-close-icon', onClick: props.close }),
            React.createElement("div", { className: 'excharge-content' },
                React.createElement("span", { className: 'excharge-qubiezhen' }),
                React.createElement("div", { className: 'content-wrapper', id: 'content-id' }, data.map(function (item, i) {
                    return React.createElement("div", { className: 'excharge-record-item', key: i },
                        React.createElement("div", { className: 'item-content-left' },
                            React.createElement("div", null,
                                item.exchangeDay,
                                " \u5151\u6362",
                                item.couponName),
                            React.createElement("div", null,
                                "\u5230\u624B\u673A\u53F7",
                                item.exchangePhoneNum)),
                        React.createElement("div", { className: "item-content-right ".concat(code[item.exchangeStatus.code]) }));
                }))),
            React.createElement("div", { className: 'excharge-close-wrapper' },
                React.createElement("span", { className: 'close-btn', onClick: props.close },
                    React.createElement("label", null, "\u5173\u95ED")))));
};
export default ExchargeHistory;
