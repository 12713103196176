import srcConfig from '../requestConfig';
import { getRequestsByRoot } from 'axios-service';
var root = srcConfig.APIS.root;
var _a = getRequestsByRoot({ root: root }), post = _a.post, get = _a.get;
/**
 * @overview 如果系统有统一的错误信息处理，请将 @errorMessageDecorator 注释
 */
var Apis = /** @class */ (function () {
    function Apis() {
        /**
         * 获取验证码
         */
        this.getVerifyCode = post('/api/mystery/user/register/phone/verifyCode');
        /**
         * 注册
         */
        this.registerFromPhone = post('/api/mystery/user/register/phone');
        /**
         * 登录
         */
        this.login = post('/api/mystery/user/login/phone');
        /**
         * 用户-找回密码
         */
        this.getBackPwd = post('/api/mystery/user/password/reset');
        // /**
        //  * 【首页】钥匙-充值-预支付-微信
        //  */
        // prepareWx = post('/api/mystery/key/deposit/prepare/wx')
        // /**
        //  * 【支付】钥匙-充值-预支付-支付宝
        //  */
        // prepareAliPay = post('/api/mystery/key/deposit/prePay/ali')
        /**
         * 获取用户信息
         */
        this.getUserInfo = get('/api/mystery/fish/user/detail');
        /**
         * 修改昵称
         */
        this.modifyName = post('/api/mystery/user/nickname/modify');
        /**
         * 排行榜
         */
        this.rankingList = get('/api/mystery/fish/ranking/list');
        /**
         * 兑换金币
         */
        this.exchargeGold = post('/api/mystery/fish/currency/diamondToGoldCoin');
        /**
         * 兑换抖币
         */
        this.exchargeDoubi = post('/api/mystery/fish/currency/diamondToDyMoney');
        /**
         * 兑换卡劵
         */
        this.exchargeCard = post('/api/mystery/fish/coupon/exchange');
        /**
         * 签到
         */
        this.signIn = post('/api/mystery/fish/user/signIn');
        /**
         * 签到列表
         */
        this.signInList = get('/api/mystery/fish/user/signIn/list');
        /**
         * 找回密码获取验证码
         */
        this.getResetVerifyCode = post('/api/mystery/user/password/reset/verifyCode');
        /**
         * 金币-充值-预支付-微信
         */
        this.prepareWx = post('/api/mystery/fish/currency/goldCoin/deposit/prePay/wx');
        /**
         * 金币-充值-预支付-支付宝
         */
        this.prepareAliPay = post('/api/mystery/fish/currency/goldCoin/deposit/prePay/ali');
        /**
         * 【捕鱼-用户】判断用户是否首充
         */
        this.firstDeposit = get('/api/mystery/fish/currency/user/firstDeposit');
        /**
         * 兑换记录
         */
        this.getRecordList = post('/api/mystery/fish/coupon/record/list');
        /**
         * 卡劵兑换列表
         */
        this.couponsList = get('/api/mystery/fish/coupon/list');
        /**
         * 兑换码
         */
        this.exchargeCode = post('/api/mystery/fish/currency/code/exchange');
    }
    return Apis;
}());
export var ServiceApis = Apis;
export default new Apis();
