import React from 'react';
import './index.less';
var KefuModal = function (props) {
    return React.createElement("div", { className: 'kefu-wrapper' },
        React.createElement("div", { className: 'kefu-content' },
            React.createElement("span", { className: 'close-icon', onClick: props.close }),
            React.createElement("div", { className: 'kefu-erweima' }),
            React.createElement("div", { className: 'kefu-scan-wrapper' },
                React.createElement("span", null),
                React.createElement("span", null),
                React.createElement("span", null)),
            React.createElement("div", { className: 'kefu-bottom-text' }, "\u5173\u6CE8\u5FAE\u4FE1\u516C\u4F17\u53F7\uFF0C\u83B7\u53D6\u66F4\u591A\u73A9\u6CD5\u548C\u4F18\u60E0")));
};
export default KefuModal;
