import React, { memo, useContext, useEffect, useState } from 'react';
import ExchargeModal from '../ExchargeModal';
import ExchargeGoldModal from '../ExchargeGoldModal';
import ExchargeHistory from '../ExchargeHistory';
import Apis from 'src/apis';
import { Context } from 'src/store';
import './index.less';
var ZuanshiExcharge = function (props) {
    var _a = useState(false), doubiExchargeShow = _a[0], setDoubiExchargeShow = _a[1];
    var _b = useState(false), goldExchargeShow = _b[0], setGoldExchargeShow = _b[1];
    var _c = useState([]), listdata = _c[0], setListData = _c[1];
    var _d = useState({}), consumeObj = _d[0], setConsumeObj = _d[1];
    var _e = useState(false), exchargeHistory = _e[0], setExchargeHistory = _e[1];
    // const [exchargeMaxGold, setExchargeMaxGold] = useState(0)
    // const [exchargeMaxDoubi, setExchargeMaxDoubi] = useState(0)
    var state = useContext(Context).state;
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var userInfoData = state.userInfoData;
    // const listdata = [{
    //   type: 1,
    //   num: 150,
    //   exchargeNum: 12
    // }, {
    //   type: 2,
    //   num: 100,
    //   exchargeNum: 2
    // }]
    var exchargeHandle = function (data, index) {
        index !== 0 ? setDoubiExchargeShow(true) : setGoldExchargeShow(true);
        setConsumeObj(data || {});
    };
    var closeExchargeHandle = function () {
        setDoubiExchargeShow(false);
    };
    var goldExchargeHandle = function () {
        setGoldExchargeShow(false);
    };
    // 获取兑换列表
    var getExchargeList = function () {
        Apis.couponsList({
            userId: userInfo.userId
        }).then(function (res) {
            setListData(res.data || []);
        }, function (err) {
            console.error(err);
        });
    };
    // 兑换历史
    var chargeHistory = function () {
        setExchargeHistory(true);
    };
    // 关闭
    var closeRecordHandle = function () {
        setExchargeHistory(false);
    };
    useEffect(function () {
        getExchargeList();
    }, []);
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'zuanshi-excharge-wrapper' },
            React.createElement("div", { className: 'zuanshi-excharge-content' },
                React.createElement("span", { className: 'zuanshi-close-icon', onClick: props.close }),
                React.createElement("span", { className: 'zuanshi-history-icon', onClick: chargeHistory }),
                React.createElement("div", { className: 'zuanshi-num' },
                    React.createElement("span", { className: 'zuanshi-icon' }),
                    React.createElement("span", null, userInfoData.diamondCount || 0)),
                React.createElement("div", { className: 'excharge-list-wrapper' }, listdata.map(function (item, i) {
                    return React.createElement("div", { key: i, className: item.type === 1 ? 'list-item list-item-jinbi' : 'list-item-doubi list-item' },
                        React.createElement("div", { className: 'list-content' },
                            React.createElement("img", { src: item.couponPicUrl, className: 'card-icon' }),
                            React.createElement("div", { className: 'list-content-left' },
                                React.createElement("span", { className: 'excharge-exptions-word' }, item.couponName),
                                React.createElement("span", { className: 'excharge-xiaohao' },
                                    React.createElement("label", null),
                                    React.createElement("label", null,
                                        "\u6D88\u8017\uFF1A",
                                        item.diamondCount))),
                            React.createElement("div", { className: 'list-content-right' },
                                React.createElement("span", { className: 'content-right-btn', onClick: exchargeHandle.bind(null, item, i) },
                                    React.createElement("label", null, "\u5151\u6362")),
                                React.createElement("span", { className: 'excharge-number' },
                                    "*\u5DF2\u5151\u6362",
                                    item.exchangedCount,
                                    "\u6B21"))));
                })))),
        doubiExchargeShow && React.createElement(ExchargeModal, { close: closeExchargeHandle, consumeObj: consumeObj, getExchargeList: getExchargeList }),
        goldExchargeShow && React.createElement(ExchargeGoldModal, { close: goldExchargeHandle, consumeObj: consumeObj, getExchargeList: getExchargeList }),
        exchargeHistory && React.createElement(ExchargeHistory, { close: closeRecordHandle }));
};
export default memo(ZuanshiExcharge);
