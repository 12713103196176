import React, { useCallback, useContext, useEffect, useState } from 'react';
import DateData, { FunctionData } from './constant';
import ModifyNick from '@pages/Component/ModifyNick';
import ZuanshiExcharge from '@pages/Component/ZuanshiExcharge';
import SortModal from '@pages/Component/SortModal';
import GameIllustrate from '@pages/Component/GameIllustrate';
import RechargeModal from '@pages/Component/RechargeModal';
import KefuModal from '@pages/Component/KefuModal';
import ExchargeCode from '@pages/Component/ExchargeCode';
import ShareModal from '@pages/Component/ShareModal';
import { Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import Apis from 'src/apis';
import { getUserInfo, setUserGoldAndDiamondInfo, Context } from 'src/store';
import './index.less';
var My = function () {
    var _a = useState(0), index = _a[0], setIndex = _a[1];
    var _b = useState(0), signRecordIndex = _b[0], setSignRecordIndex = _b[1];
    var _c = useState(false), showModify = _c[0], setShowModify = _c[1];
    var _d = useState(false), exchargeShow = _d[0], setExchargeShow = _d[1];
    var _e = useState(false), sortModalShow = _e[0], setSortModalShow = _e[1];
    var _f = useState(false), gameIllistateShow = _f[0], setGameIllistateShow = _f[1];
    var _g = useState(false), rechargeShow = _g[0], setRechargeShow = _g[1];
    var _h = useState(false), kefuShow = _h[0], setKefuShow = _h[1];
    var _j = useState(false), showExchargeCode = _j[0], setShowExchargeCode = _j[1];
    var _k = useState(false), shareModalShow = _k[0], setShareModalShow = _k[1];
    // const [userInfoMessage, setUserInfoMessage] = useState<any>({
    //   diamondCount: 0,
    //   goldCoinCount: 0,
    //   user: {}
    // })
    // const [diamondNumber, setDiamondNumber] = useState(0)
    var _l = useState(), name = _l[0], setName = _l[1];
    var _m = useContext(Context), state = _m.state, dispatch = _m.dispatch;
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var userInfoData = state.userInfoData;
    var navigate = useNavigate();
    var signHandle = useCallback(function (index) {
        console.log(index);
        if (!userInfo.userId) {
            return Toast.show({
                content: '请登录'
            });
        }
        if (index > signRecordIndex + 1) {
            return Toast.show({
                content: '请连续签到'
            });
        }
        var today = new Date();
        // const year = today.getFullYear()
        var currentDay = today.getDate();
        // const month = today.getMonth() + 1
        var cacheDay = parseInt(window.localStorage.getItem('day') || '0');
        if (currentDay != cacheDay) {
            window.localStorage.setItem('day', String(currentDay));
            setIndex(index);
            signIn(index);
        }
        else {
            return Toast.show({
                content: '已经签到'
            });
        }
    }, [signRecordIndex]);
    var modifyHandle = function (name) {
        setShowModify(true);
        setName(name);
    };
    var close = function (newName) {
        setShowModify(false);
        if (newName) {
            dispatch(getUserInfo());
        }
    };
    var rechargeHandle = function () {
        setRechargeShow(true);
    };
    var closeRechargeModal = function () {
        setRechargeShow(false);
    };
    var zuanshiExcharge = function () {
        setExchargeShow(true);
        // setDiamondNumber(num)
        // console.log(num)
    };
    var closeExcharge = function () {
        setExchargeShow(false);
    };
    var closeSortModal = function () {
        setSortModalShow(false);
    };
    var closeGameIllistateModal = function () {
        setGameIllistateShow(false);
    };
    var dealWithHandle = function (item) {
        switch (item.key) {
            case 1:
                if (!userInfo.userId) {
                    return Toast.show({
                        content: '请登录'
                    });
                }
                setSortModalShow(true);
                break;
            case 6:
                // setKefuShow(true)
                zuanshiExcharge();
                break;
            case 7:
                // setGameIllistateShow(true)
                rechargeHandle();
                break;
            case 4:
                setShowExchargeCode(true);
                break;
            case 5:
                if (!userInfo.userId) {
                    return Toast.show({
                        content: '请登录'
                    });
                }
                setShareModalShow(true);
                break;
            default:
                break;
        }
    };
    var loginHandle = function () {
        if (!userInfo.userId) {
            navigate('/login');
        }
    };
    var logoutHandle = function () {
        window.localStorage.removeItem('user');
        dispatch(setUserGoldAndDiamondInfo({
            diamondCount: 0,
            goldCoinCount: 0,
            user: {}
        }));
    };
    // 签到
    var signIn = function (index) {
        Apis.signIn({
            userId: userInfo.userId,
            signInDayNum: index
        }).then(function (res) {
            if (res.data.signInResult) {
                console.log('成功');
                dispatch(getUserInfo());
            }
        });
    };
    // 签到列表
    var signInList = function () {
        Apis.signInList({
            userId: userInfo.userId
        }).then(function (res) {
            setSignRecordIndex(res.data);
        });
    };
    var goHome = function () {
        window.location.href = window.location.origin + '/game.html';
        // navigate('/game.html?time=' + Math.random())
    };
    useEffect(function () {
        dispatch(getUserInfo());
        signInList();
    }, []);
    return React.createElement("div", { className: 'my-wrapper' },
        React.createElement("div", { className: 'my-top-wrapper' },
            React.createElement("div", { className: 'my-top-gold', onClick: rechargeHandle },
                React.createElement("span", { className: 'gold-num' }, userInfo.userId ? userInfoData.goldCoinCount : 0),
                React.createElement("span", { className: 'gold-add' })),
            React.createElement("div", { className: 'my-top-zuanshi', onClick: zuanshiExcharge },
                React.createElement("span", { className: 'zuanshi-num' }, userInfo.userId ? userInfoData.diamondCount : 0),
                React.createElement("span", { className: 'zuanshi-add' }))),
        React.createElement("div", { className: 'user-message-wrapper' },
            React.createElement("div", { className: 'user-message' },
                React.createElement("div", { className: 'user-header' }),
                React.createElement("div", { className: 'user-name-wrapper' },
                    React.createElement("span", { className: 'user-name-top' },
                        React.createElement("label", { onClick: loginHandle }, userInfo.userId ? userInfoData.user.nickName : '未登录'),
                        React.createElement("label", { className: userInfo.userId ? '' : 'hide', onClick: modifyHandle.bind(null, userInfoData.user.nickName) })),
                    React.createElement("span", { className: userInfo.userId ? 'user-name-option' : 'user-name-option hide', onClick: logoutHandle },
                        React.createElement("label", null, "\u9000\u51FA"),
                        React.createElement("label", null)))),
            React.createElement("div", { className: 'user-buyu-btn', onClick: goHome },
                React.createElement("label", null, "\u6355\u9C7C\u53BB"))),
        React.createElement("div", { className: 'my-function-wrapper' }, FunctionData.map(function (item, i) {
            return React.createElement("div", { key: i, className: 'function-item', onClick: dealWithHandle.bind(null, item) },
                React.createElement("img", { src: item.icon, className: 'function-item-icon' }),
                React.createElement("span", { className: 'function-item-name' }, item.name));
        })),
        React.createElement("div", { className: 'sign-in-wrapper' }, DateData.map(function (item, i) {
            return React.createElement("div", { key: i, className: 'sign-item' },
                React.createElement("img", { src: item.icon, className: 'sign-item-img', onClick: signHandle.bind(null, item.key) }),
                React.createElement("img", { src: item.signIcon, className: (i + 1 === index || signRecordIndex >= i + 1) ? 'sign-icon-mark' : 'hide' }),
                React.createElement("span", { className: 'sign-item-number' },
                    "x",
                    item.number),
                React.createElement("span", { className: 'sign-item-days' },
                    "\u7B2C",
                    item.key,
                    "\u5929"));
        })),
        React.createElement("div", { className: 'left-fixed-wrapper' },
            React.createElement("div", { className: 'text-wrapper', onClick: function () { return setKefuShow(true); } },
                React.createElement("span", null, "\u5BA2\u670D")),
            React.createElement("div", { className: 'text-wrapper', onClick: function () { return setGameIllistateShow(true); } },
                React.createElement("span", null, "\u6E38\u620F\u8BF4\u660E"))),
        showModify && React.createElement(ModifyNick, { name: name, close: close }),
        exchargeShow && React.createElement(ZuanshiExcharge, { close: closeExcharge }),
        sortModalShow && React.createElement(SortModal, { close: closeSortModal, visible: sortModalShow }),
        gameIllistateShow && React.createElement(GameIllustrate, { close: closeGameIllistateModal }),
        rechargeShow && React.createElement(RechargeModal, { close: closeRechargeModal }),
        kefuShow && React.createElement(KefuModal, { close: function () { return setKefuShow(false); } }),
        showExchargeCode && React.createElement(ExchargeCode, { close: function () { return setShowExchargeCode(false); } }),
        shareModalShow && React.createElement(ShareModal, { close: function () { return setShareModalShow(false); } }));
};
export default My;
