import React, { useEffect, useState } from 'react';
import { Popup, AutoCenter, Toast, Dialog } from 'antd-mobile';
import Apis from 'src/apis';
import { stringifyParams } from 'src/utils';
import './index.less';
var RechargeModal = function (props) {
    var _a = useState(false), payVisible = _a[0], setPayVisible = _a[1];
    var _b = useState(0), choiceGoldNum = _b[0], setChoiceGoldNum = _b[1];
    var _c = useState(false), firstCharge = _c[0], setFirstCharge = _c[1];
    var _d = useState(''), htmlText = _d[0], setHtmlText = _d[1];
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var chargeList = [{
            img: 'https://cdn.tuanzhzh.com/buyudaren/shouchongthree.png',
            gold: 10,
            money: 1
        }, {
            img: 'https://cdn.tuanzhzh.com/buyudaren/shouchongtwo.png',
            gold: 300,
            money: 29
        }, {
            img: 'https://cdn.tuanzhzh.com/buyudaren/shouchongtwo.png',
            gold: 500,
            money: 49
        }, {
            img: 'https://cdn.tuanzhzh.com/buyudaren/shouchongtwo.png',
            gold: 1000,
            money: 98
        }, {
            img: 'https://cdn.tuanzhzh.com/buyudaren/shouchongtwo.png',
            gold: 4000,
            money: 398
        }, {
            img: 'https://cdn.tuanzhzh.com/buyudaren/shouchongtwo.png',
            gold: 7000,
            money: 698
        }];
    var rechargeHandle = function (num) {
        console.log(num);
        setPayVisible(true);
        setChoiceGoldNum(num);
    };
    // 微信支付
    var wechatPay = function () {
        Toast.show({
            icon: 'loading',
            content: '加载中…'
        });
        var str = stringifyParams({
            // keyType: rechargeKeyInfo.keyCountInfo.keyType,
            // keyCount: rechargeKeyInfo.keyCountInfo.keyCount,
            userId: userInfo.userId,
            from: 'h5'
        });
        setTimeout(function () {
            Dialog.confirm({
                content: '您是否已支付完成？',
                bodyStyle: {
                    background: '#fff'
                }
            }).then(function (res) {
                if (res) {
                    window.location.reload();
                }
                else {
                    console.log('点击取消');
                }
            });
        }, 1500);
        // 判断是否是小程序环境
        if (window.__wxjs_environment === 'miniprogram') {
            window.wx.miniProgram.navigateTo({
                url: "/pages/index/index?".concat(str)
            });
        }
        else {
            getWeixinUrl();
        }
    };
    var getWeixinUrl = function () {
        // const str = stringifyParams({
        //   keyType: rechargeKeyInfo.keyCountInfo.keyType,
        //   keyCount: rechargeKeyInfo.keyCountInfo.keyCount,
        //   userId: userInfo.userId,
        //   from: 'h5'
        // })
        // Apis.createMiniProgram({
        //   path: '/pages/index/index',
        //   'env_version': 'release',
        //   query: str
        // }).then(res => {
        //   if (!res.code) {
        //     closePay()
        //     closeModal()
        //     window.location.href = res.data
        //   } else {
        //     Toast.show({
        //       content: '支付异常，稍后重试'
        //     })
        //   }
        // }).catch(() => {
        //   Toast.show({
        //     content: '支付异常，稍后重试'
        //   })
        // }).finally(() => {
        //   Toast.clear()
        // })
    };
    // 微信支付
    // const wechatPay = () => {
    //   setPayVisible(false)
    //   Toast.show({
    //     icon: 'loading',
    //     content: '加载中…'
    //   })
    //   Apis.prepareWx({
    //     userId: userInfo.userId,
    //     goldCoinCount: choiceGoldNum * 1
    //   }).then(res => {
    //     console.log(res.data)
    //   })
    // }
    // 支付宝
    var aliPay = function () {
        // setHtmlText('')
        // console.log(document.forms[0].submit())
        setPayVisible(false);
        Toast.show({
            icon: 'loading',
            content: '加载中…'
        });
        Apis.prepareAliPay({
            userId: userInfo.userId,
            goldCoinCount: choiceGoldNum * 1
        }).then(function (res) {
            var _a;
            console.log(res.data);
            setHtmlText((_a = res.data) === null || _a === void 0 ? void 0 : _a.payForm);
            document.forms[0].submit();
        }, function () {
            Toast.show({
                content: '支付异常，稍后重试'
            });
        }).finally(function () {
            Toast.clear();
        });
    };
    // 判断是否首次充值
    var firstDeposit = function () {
        Apis.firstDeposit({
            userId: userInfo.userId
        }).then(function (res) {
            setFirstCharge(res.data);
        });
    };
    useEffect(function () {
        firstDeposit();
    }, []);
    return React.createElement("div", { className: 'recharge-modal-wrapper' },
        React.createElement("div", { className: 'recharge-content' },
            React.createElement("span", { className: 'recharge-close-icon', onClick: props.close }),
            React.createElement("div", { className: 'recharge-item-wrapper' }, chargeList.map(function (item, i) {
                return React.createElement("div", { className: 'recharge-item', key: i },
                    React.createElement("div", { className: 'recharge-item-top' }),
                    firstCharge ? React.createElement("img", { className: 'recharge-icon', src: item.img }) : '',
                    React.createElement("div", { className: 'recharge-item-gold' },
                        React.createElement("span", null, item.gold),
                        React.createElement("span", null, "\u91D1\u5E01")),
                    React.createElement("div", { className: 'recharge-btn', onClick: rechargeHandle.bind(null, item.gold) },
                        "\uFFE5",
                        item.money));
            }))),
        React.createElement(Popup, { className: 'recharge-popup-wrapper', visible: payVisible, onMaskClick: function () {
                setPayVisible(false);
            }, showCloseButton: true, onClose: function () {
                setPayVisible(false);
            }, bodyStyle: {
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                minHeight: '30vh'
            } },
            React.createElement(AutoCenter, { className: 'popup-title' }, "\u652F\u4ED8\u65B9\u5F0F\u9009\u62E9"),
            React.createElement("div", { className: 'pay-list-wrapper' },
                React.createElement("div", { className: 'pay-list', style: { display: 'none' }, onClick: wechatPay },
                    React.createElement("span", { className: 'wechat' }),
                    React.createElement("span", { className: 'pay-word' }, "\u5FAE\u4FE1\u652F\u4ED8")),
                React.createElement("div", { className: 'pay-list', onClick: aliPay },
                    React.createElement("span", { className: 'zhifubao' }),
                    React.createElement("span", { className: 'pay-word' }, "\u652F\u4ED8\u5B9D\u652F\u4ED8")))),
        React.createElement("div", { dangerouslySetInnerHTML: { __html: htmlText } }));
};
export default RechargeModal;
